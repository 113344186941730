import { Transition } from '@tailwindui/react';
import { type ReactNode, useMemo, useRef, useState } from 'react';

import { ArrowDownIcon } from '../../../src/components/icons/Arrows';
import { CloseIcon } from '../../../src/components/icons/CloseIcon';
import { useOutsideClick } from '../../../src/hooks/useOutsideClick';
import {
  type FilterOption,
  FilterPresets,
  type GameLikeFilterOptions,
  MAX_PLAYER_COUNT_FILTER,
  MIN_PLAYER_COUNT_FILTER,
  parsePlayerCountFilter,
} from '../GamePack/GamePackFilters';

function Separator() {
  return (
    <div className='border-r h-5 border-secondary border-opacity-40'></div>
  );
}

function CheckGroup<T>(props: {
  options: FilterOption<T>[];
  values: FilterOption<T>[];
  onChange: (values: FilterOption<T>[]) => void;
}) {
  const { options, values, onChange } = props;

  const handleChange = (option: FilterOption<T>) => {
    if (values.find((value) => value.key === option.key)) {
      onChange(values.filter((value) => value.key !== option.key));
    } else {
      onChange([...values, option]);
    }
  };

  return (
    <div className='flex flex-col gap-2 whitespace-nowrap'>
      {options.map((option) => (
        <label
          key={option.key}
          className='flex items-center gap-1 cursor-pointer'
        >
          <input
            type='checkbox'
            className=''
            checked={!!values.find((value) => value.key === option.key)}
            onChange={() => handleChange(option)}
          />
          <p className='ml-2 text-black text-sms font-medium'>{option.label}</p>
        </label>
      ))}
    </div>
  );
}

function FilterButton(props: {
  title: string;
  selected: boolean;
  opened: boolean;
  onToggleOpened: (opened: boolean) => void;
  onReset: () => void;
  menu?: React.ReactNode;
}) {
  const { title, selected, opened, onToggleOpened, onReset, menu } = props;

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => onToggleOpened(false), null, !opened);

  return (
    <div ref={ref} className='relative h-full flex justify-center items-center'>
      <button
        type='button'
        onClick={() => onToggleOpened(!opened)}
        className={`btn pl-5 pr-2 h-full flex items-center gap-2 ${
          selected ? ' text-[#0029FF]' : 'text-[#120E14]'
        } text-opacity-60 hover:text-opacity-100 transition-all`}
      >
        <p className='text-sms font-bold min-w-25'>{title}</p>
        <div className='w-4 h-4 flex items-center justify-center'>
          {selected ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                onReset();
              }}
              className='w-full h-full flex items-center justify-center'
            >
              <CloseIcon className='w-3 h-3 fill-current' />
            </div>
          ) : (
            <div
              className={`w-full h-full transform ${
                opened ? '-rotate-180' : ''
              } transition-transform`}
            >
              <ArrowDownIcon className='w-full h-full fill-current' />
            </div>
          )}
        </div>
      </button>

      <Transition
        show={opened}
        enter='transition-all transform ease-in'
        enterFrom='opacity-0 -translate-y-2'
        enterTo='opacity-100'
        leave='transition-all transform ease-out'
        leaveFrom='opacity-100'
        leaveTo='opacity-0 -translate-y-2'
      >
        {(ref) => (
          <div
            className='
              absolute z-30 top-full mt-4
              bg-white text-[#120E14] text-sms font-medium
              rounded-xl p-4
              '
            ref={ref}
          >
            {menu}
          </div>
        )}
      </Transition>
    </div>
  );
}

function HeadcountInputBox(props: {
  count: number | null;
  onChange: (count: number | null) => void;
}) {
  const { count, onChange } = props;
  const [err, setErr] = useState<ReactNode | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErr(null);

    if (e.target.value === '') {
      onChange(null);
      return;
    }

    if (e.target.valueAsNumber < MIN_PLAYER_COUNT_FILTER) {
      setErr(`Minimum ${MIN_PLAYER_COUNT_FILTER}`);
      return;
    }
    if (e.target.valueAsNumber > MAX_PLAYER_COUNT_FILTER) {
      setErr(
        <div className='text-3xs'>
          <span className='text-red-002'>
            Maximum {MAX_PLAYER_COUNT_FILTER}.
          </span>{' '}
          <br />
          Planning for a larger group? <br />
          Reach out to{' '}
          <a
            className='text-primary underline'
            href='mailto:support@lunapark.com'
          >
            support@lunapark.com
          </a>
          .
        </div>
      );
      return;
    }
    onChange(e.target.valueAsNumber);
  };

  return (
    <div>
      <div className='flex items-center gap-4'>
        <p className='whitespace-nowrap'>Estimated Headcount</p>
        <input
          type='number'
          value={count || ''}
          onChange={handleChange}
          className='w-10 flex-1 h-10 m-0 text-center bg-[#120E14] bg-opacity-15 rounded-lg'
          min={MIN_PLAYER_COUNT_FILTER}
          max={MAX_PLAYER_COUNT_FILTER}
          placeholder='0'
        />
      </div>
      {err}
    </div>
  );
}

export type PublicLibraryFilterKey = keyof GameLikeFilterOptions;

export const PublicLibraryFilterKeysMain = [
  'playerCount',
  'duration',
  'audience',
  'competitionLevel',
  'playStyle',
] as PublicLibraryFilterKey[];

export const PublicLibraryFilterKeysAllLoggedOut = [
  'playerCount',
  'duration',
  'audience',
  'competitionLevel',
  'difficulty',
  'playStyle',
] as PublicLibraryFilterKey[];

export const PublicLibraryFilterKeysAllLoggedIn = [
  'playerCount',
  'duration',
  'audience',
  'competitionLevel',
  'difficulty',
  'playHistory',
  'playStyle',
] as PublicLibraryFilterKey[];

export function PublicLibraryFilter(props: {
  filterOptions: Partial<GameLikeFilterOptions>;
  showMenuKeys: PublicLibraryFilterKey[];
  withApplyButton?: boolean;
  onChange: (options: Partial<GameLikeFilterOptions>) => void;
  onApply: (options: Partial<GameLikeFilterOptions>) => void;
}) {
  const { filterOptions, showMenuKeys, withApplyButton, onChange, onApply } =
    props;

  const [openedMenu, setOpenedMenu] = useState<PublicLibraryFilterKey | null>(
    null
  );

  const options = useMemo(() => {
    return {
      duration: [],
      audience: [],
      competitionLevel: [],
      difficulty: [],
      playHistory: [],
      playerCount: [],
      playStyle: [],
      ...filterOptions,
    };
  }, [filterOptions]);

  const handleUpdateOptions = (updates: Partial<GameLikeFilterOptions>) => {
    const newOptions = { ...options, ...updates };
    onChange(newOptions);
    if (!withApplyButton) {
      onApply(newOptions);
    }
  };

  const handleApply = () => {
    const isSelected = Object.values(options).some(
      (values) => values.length > 0
    );
    if (isSelected) {
      onApply(options);
    } else if (showMenuKeys.length > 0) {
      setOpenedMenu(showMenuKeys[0]);
    }
  };

  const showMenus = showMenuKeys.map((key) => {
    switch (key) {
      case 'playerCount':
        return (
          <FilterButton
            key={key}
            title='Headcount'
            selected={options.playerCount.length > 0}
            opened={openedMenu === 'playerCount'}
            onToggleOpened={(opened) =>
              setOpenedMenu(opened ? 'playerCount' : null)
            }
            onReset={() => handleUpdateOptions({ playerCount: [] })}
            menu={
              <HeadcountInputBox
                count={options.playerCount[0]?.value}
                onChange={(count) =>
                  handleUpdateOptions({
                    playerCount: count
                      ? [
                          {
                            key: String(count),
                            value: count,
                            label: String(count),
                          },
                        ]
                      : [],
                  })
                }
              />
            }
          />
        );
      case 'duration':
        return (
          <FilterButton
            key={key}
            title='Duration'
            selected={options.duration.length > 0}
            opened={openedMenu === 'duration'}
            onToggleOpened={(opened) =>
              setOpenedMenu(opened ? 'duration' : null)
            }
            onReset={() => handleUpdateOptions({ duration: [] })}
            menu={
              <CheckGroup
                options={FilterPresets.duration.options}
                values={options.duration}
                onChange={(values) => handleUpdateOptions({ duration: values })}
              />
            }
          />
        );
      case 'audience':
        return (
          <FilterButton
            key={key}
            title='Audience'
            selected={options.audience.length > 0}
            opened={openedMenu === 'audience'}
            onToggleOpened={(opened) =>
              setOpenedMenu(opened ? 'audience' : null)
            }
            onReset={() => handleUpdateOptions({ audience: [] })}
            menu={
              <CheckGroup
                options={FilterPresets.audience.options}
                values={options.audience}
                onChange={(values) => handleUpdateOptions({ audience: values })}
              />
            }
          />
        );
      case 'competitionLevel':
        return (
          <FilterButton
            key={key}
            title='Competitiveness'
            selected={options.competitionLevel.length > 0}
            opened={openedMenu === 'competitionLevel'}
            onToggleOpened={(opened) =>
              setOpenedMenu(opened ? 'competitionLevel' : null)
            }
            onReset={() => handleUpdateOptions({ competitionLevel: [] })}
            menu={
              <CheckGroup
                options={FilterPresets.competitionLevel.options}
                values={options.competitionLevel}
                onChange={(values) =>
                  handleUpdateOptions({ competitionLevel: values })
                }
              />
            }
          />
        );
      case 'difficulty':
        return (
          <FilterButton
            key={key}
            title='Difficulty'
            selected={options.difficulty.length > 0}
            opened={openedMenu === 'difficulty'}
            onToggleOpened={(opened) =>
              setOpenedMenu(opened ? 'difficulty' : null)
            }
            onReset={() => handleUpdateOptions({ difficulty: [] })}
            menu={
              <CheckGroup
                options={FilterPresets.difficulty.options}
                values={options.difficulty}
                onChange={(values) =>
                  handleUpdateOptions({ difficulty: values })
                }
              />
            }
          />
        );
      case 'playHistory':
        return (
          <FilterButton
            key={key}
            title='Play History'
            selected={options.playHistory.length > 0}
            opened={openedMenu === 'playHistory'}
            onToggleOpened={(opened) =>
              setOpenedMenu(opened ? 'playHistory' : null)
            }
            onReset={() => handleUpdateOptions({ playHistory: [] })}
            menu={
              <CheckGroup
                options={FilterPresets.playHistory.options}
                values={options.playHistory}
                onChange={(values) =>
                  handleUpdateOptions({ playHistory: values })
                }
              />
            }
          />
        );
      case 'playStyle':
        return (
          <FilterButton
            key={key}
            title='Play Style'
            selected={options.playStyle.length > 0}
            opened={openedMenu === 'playStyle'}
            onToggleOpened={(opened) =>
              setOpenedMenu(opened ? 'playStyle' : null)
            }
            onReset={() => handleUpdateOptions({ playStyle: [] })}
            menu={
              <CheckGroup
                options={FilterPresets.playStyle.options}
                values={options.playStyle}
                onChange={(values) =>
                  handleUpdateOptions({ playStyle: values })
                }
              />
            }
          />
        );
      default:
        return null;
    }
  });

  const showMenusWithSeparator = showMenus.flatMap((menu, index) =>
    index < showMenus.length - 1 ? [menu, <Separator key={index} />] : [menu]
  );

  return (
    <div className='h-15 p-2 bg-white rounded-xl flex items-center gap-10'>
      <div className='h-full flex items-center gap-2'>
        {showMenusWithSeparator}
      </div>
      {withApplyButton ? (
        <button
          type='button'
          onClick={handleApply}
          className='btn-secondary w-40 h-full text-sms'
        >
          Find Matches
        </button>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export function encodeFilterOptions(
  filterOptions: Partial<GameLikeFilterOptions>,
  options?: {
    init?: URLSearchParams;
  }
) {
  const params = options?.init ?? new URLSearchParams();
  for (const [name, options] of Object.entries(filterOptions)) {
    params.delete(name);
    if (options.length > 0) {
      options.forEach((option) => {
        params.append(name, option.key);
      });
    }
  }
  return params;
}

export function parseFilterOptions(params: URLSearchParams) {
  const filterOptions: Partial<GameLikeFilterOptions> = {};
  for (const [name0, filter0] of Object.entries(FilterPresets)) {
    const name = name0 as keyof typeof FilterPresets;
    const filter =
      filter0 as (typeof FilterPresets)[keyof typeof FilterPresets];

    const keys = params.getAll(name);
    const options: (typeof filter.options)[number][] = [];
    keys.forEach((k) => {
      const option = filter.options.find((o) => o.key === k);
      if (option) options.push(option);
    });
    if (options.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filterOptions[name] = options as any;
    }
  }
  // not all search filters are presets.
  const playerCount = parsePlayerCountFilter(params.get('playerCount'));
  if (playerCount) {
    filterOptions.playerCount = [playerCount];
  }
  return filterOptions;
}
